import React, { Component } from 'react';
import { Toolbar } from './Toolbar/Toolbar';
import { SideDrawer } from './SideDrawer/SideDrawer';
import Frontpage from './../pages/Frontpage';
import Footer from './Footer/footer';
import Header from './Header/Header';
import { withRouter } from 'react-router-dom';

class Layout extends Component {
  constructor () {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  state = {
    sideDrawerOpen: false,
    displayText: '',
    contentProvider: '',
    language: ''
  };
  // TODO: fix
  UNSAFE_componentWillMount () {
    const pathName = window.location.pathname.slice(1).toLowerCase();
    let contentProvider = '';

    if (pathName === 'oys') {
      contentProvider = 'OYS';
    }

    if (pathName === 'tays') {
      contentProvider = 'TAYS';
    }

    this.setState({
      contentProvider
    });
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  displayTextClickHandler = param => {
    this.setState({ displayText: param });
  };

  changeLanguageClickHandler = param => {
    this.setState({ language: param });
    const { i18n } = this.props;

    i18n.changeLanguage(param);

    // cookie.set('language', param);
    // window.location.reload();
  };

  getViewPortSize = () => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      // console.log(vh);
    });
    // console.log(vh);
  };

  render () {
    const { width } = this.state;
    const isMobile = width <= 768;
    this.getViewPortSize();

    let showFrontPage = true;

    if (this.props.location.pathname === '/ViewChatWebsite') {
      // Hide frontpage if window height less than 1200px
      if (window.innerHeight <= 1200) {
        showFrontPage = false;
      }
    }
    if (isMobile) {
      return (
        <>
          <div className="showOnlyOnMobile">
            <div className="centerOnPage">
              <div className="mainApp">
                <Toolbar
                  drawerClickHandler={this.drawerToggleClickHandler}
                  displayText={this.state.displayText}
                  changeTextHandler={this.displayTextClickHandler}
                  closeHandler={this.drawerToggleClickHandler}
                  contentProvider={this.state.contentProvider}
                  changeLanguage={this.changeLanguageClickHandler}
                />
                <SideDrawer
                  show={this.state.sideDrawerOpen}
                  closeHandler={this.drawerToggleClickHandler}
                  changeTextHandler={this.displayTextClickHandler}
                  contentProvider={this.state.contentProvider}
                  changeLanguage={this.changeLanguageClickHandler}
                />

                <div className="marginTop-mobile">
                  {/* <img className="imagetest1" src={testiImage} alt="jejasdf" /> */}
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="showOnlyOnDesktop">
            <div className="centerOnPage">
              <div className="desktopFlex">
                <div className="mainApp">
                  <Header />
                  <br />
                  <Toolbar
                    drawerClickHandler={this.drawerToggleClickHandler}
                    displayText={this.state.displayText}
                    changeTextHandler={this.displayTextClickHandler}
                    closeHandler={this.drawerToggleClickHandler}
                    contentProvider={this.state.contentProvider}
                    changeLanguage={this.changeLanguageClickHandler}
                  />
                  <SideDrawer
                    show={this.state.sideDrawerOpen}
                    closeHandler={this.drawerToggleClickHandler}
                    changeTextHandler={this.displayTextClickHandler}
                    contentProvider={this.state.contentProvider}
                    changeLanguage={this.changeLanguageClickHandler}
                  />
                  <div className="marginTop">{this.props.children}</div>
                  {showFrontPage && <Frontpage />}

                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default withRouter(Layout);
