import React from 'react';
import {
  Container,
  Header,
  Input,
  Button,
  Message,
  Form
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

class ChangePassword extends React.Component {
  state = {
    password: '',
    passwordError: ''
  };

  onSubmit = async e => {
    this.setState({
      passwordError: ''
    });
    const { password } = this.state;
    const response = await this.props.mutate({
      variables: { password }
    });

    const { ok, errors } = response.data.register;

    if (ok) {
      this.props.history.push('/login');
    } else {
      const err = {};
      errors.forEach(({ path, message }) => {
        // err['passwordError'] = 'too long..';
        err[`${path}Error`] = message;
      });

      this.setState(err);
    }

    console.log(response);
  };

  onChange = e => {
    const { name, value } = e.target;
    // name = "login" --> this.setState({ login: value}) etc..
    this.setState({ [name]: value });
  };

  render () {
    const { password, passwordError } = this.state;

    const errorList = [];
    if (passwordError) errorList.push(passwordError);

    // !! means casting to boolean
    // !'' => true
    // !!'' => false
    return (
      <Container text>
        <Header as="h2">Aseta salasana</Header>
        <Form>
          <Form.Field error={!!passwordError}>
            <Input
              name="password"
              onChange={this.onChange}
              value={password}
              type="password"
              placeholder="Password"
              autoComplete="password"
              fluid
            />
          </Form.Field>
          <Button onClick={this.onSubmit}>Lähetä</Button>
        </Form>
        {errorList.length ? (
          <Message
            error
            header="There was some errors with your submission"
            list={errorList}
          />
        ) : null}
      </Container>
    );
  }
}

const changePasswordMutation = gql`
  mutation($password: String!) {
    register(password: $password) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export default graphql(changePasswordMutation)(ChangePassword);
