import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class WidthLayouts extends Component {
    constructor () {
        super();
        this.state = {
          width: window.innerWidth,
        };
      }

      // TODO: fix
      UNSAFE_componentWillMount () {
        window.addEventListener('resize', this.handleWindowSizeChange);
      }

      // make sure to remove the listener
      // when the component is not mounted anymore
      // TODO: fix
      componentWillUnmount () {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      }

      handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
      };

      render () {
        const { width } = this.state;
        const isMobile = width <= 768;

        // the rest is the same...

        if (isMobile) {
          return (
            <Redirect
              to={{
              pathname: '/FrontPage'
              }}
            />
          );
        }
        else {
          return (
            <Redirect
              to={{
              pathname: '/Carbohydrates'
              }}
            />
          );
        }
      }
}

export default WidthLayouts