import gql from 'graphql-tag';

export const meQuery = gql`
  {
    me {
      id
      login
      displayname
      role
      teams {
        id
        name
        admin
        channels {
          id
          name
          dm
        }
      }
    }
  }
`;

export const emptystuff = {};

export const getTeamMembersQuery = gql`
  query($teamId: ID!) {
    getTeamMembers(teamId: $teamId) {
      id
      login
      displayname
    }
  }
`;
