import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

import ScrollableFeed from 'react-scrollable-feed';
// import cookie from 'js-cookie';
import '../react-chat-elements.scss';

import { formatDate } from '../../../index';
import Message from '../components/Message';

const newChannelMessageSubscription = gql`
  subscription($channelId: ID!) {
    newChannelMessage(channelId: $channelId) {
      id
      text
      file
      filetype
      user {
        id
        displayname
        role
      }
      createdAt
    }
  }
`;

class MessageContainer extends React.Component {
  constructor (props) {
    super(props);

    this.keyCount = 0;
    this.getKey = this.getKey.bind(this);
    // const chatOtherUserID = cookie.get('ChatOtherUserID');

    // if (chatOtherUserID) {
    //   this.channelId = chatOtherUserID;
    // }
  }
  state = {
    hasMoreItems: true
  };

  getKey () {
    return this.keyCount++;
  }
// TODO: fix
  UNSAFE_componentWillMount () {
    this.unsubscribe = this.subscribe(this.props.channelId);
  }

  // TODO: fix
  UNSAFE_componentWillReceiveProps ({ data: { messages }, channelId }) {
    if (this.props.channelId !== channelId) {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      this.unsubscribe = this.subscribe(channelId);
    }

    if (
      this.scroller &&
      this.scroller.scrollTop < 100 &&
      this.props.data.messages &&
      messages &&
      this.props.data.messages.length !== messages.length
    ) {
      // 35 items
      // console.log(this.scroller.scrollHeight);
      const heightBeforeRender = this.scroller.scrollHeight;
      // wait for 70 items to render
      setTimeout(() => {
        if (this.scroller) {
          // console.log(this.scroller.scrollHeight);
          this.scroller.scrollTop =
            this.scroller.scrollHeight - heightBeforeRender;
        }
      }, 100);
    }
  }

  componentWillUnmount () {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  subscribe = channelId =>
    this.props.data.subscribeToMore({
      document: newChannelMessageSubscription,
      variables: {
        channelId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        return {
          ...prev,
          messages: [subscriptionData.data.newChannelMessage, ...prev.messages]
        };
      }
    });

  handleLoadMore = () => {
    // prettier-ignore
    const { data: { messages, fetchMore }, channelId } = this.props;
    fetchMore({
      variables: {
        channelId,
        cursor: messages[messages.length - 1].createdAt
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.messages.length < 35) {
          this.setState({ hasMoreItems: false });
        }

        return {
          ...previousResult,
          messages: [...previousResult.messages, ...fetchMoreResult.messages]
        };
      }
    });
  };

  handleScroll = () => {
    // prettier-ignore
    const { data: { messages, fetchMore }, channelId } = this.props;
    if (
      this.scroller &&
      this.scroller.scrollTop < 100 &&
      this.state.hasMoreItems &&
      messages.length >= 35
    ) {
      fetchMore({
        variables: {
          channelId,
          cursor: messages[messages.length - 1].createdAt
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }

          if (fetchMoreResult.messages.length < 35) {
            this.setState({ hasMoreItems: false });
          }

          return {
            ...previousResult,
            messages: [...previousResult.messages, ...fetchMoreResult.messages]
          };
        }
      });
    }
  };

  render () {
    const {
      data: { loading, messages }
    } = this.props;

    return loading ? null : (
      <div
        className="messages"
        // onScroll={this.handleScroll}
        ref={scroller => {
          this.scroller = scroller;
        }}>
        <ScrollableFeed forceScroll={true}>
          {this.state.hasMoreItems && messages && messages.length >= 35 && (
            <Button
              onClick={() => {
                this.handleScroll();
              }}>
                <Trans i18nKey="chat.loadMore" />
            </Button>
          )}
          {messages &&
            messages
              .slice()
              .reverse()
              .map(m => {
                return (
                  <div key={`${m.id}-message`}>
                    <Message
                      currentUser={
                        m.user.displayname === this.props.displayName
                      }
                      displayName={m.user.displayname}
                      messageText={m.text}
                      messageDate={formatDate(m.createdAt)}
                      role={m.user.role}
                      file={m.file}
                      filetype={m.filetype}
                    />
                  </div>
                );
              })}
        </ScrollableFeed>
      </div>
    );
  }
}

const messagesQuery = gql`
  query($cursor: String, $channelId: ID!) {
    messages(cursor: $cursor, channelId: $channelId) {
      id
      text
      file
      filetype
      user {
        id
        displayname
        role
      }
      createdAt
    }
  }
`;

export default graphql(messagesQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      channelId: props.channelId
    }
  })
})(MessageContainer);
