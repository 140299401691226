import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { displayLinks } from './DisplayLinks';
import { displaySubfields } from './DisplaySubfields';
import { LinkWithoutPopupBold } from './LinkWithoutPopup';
import { WithHTML } from './WithHTML';
import { WithImage } from './WithImage';
import { WithLogo } from './WithLogo';
import { WithPDF } from './WithPDF';
import { WithSource } from './WithSource';


export function displayAccordion (data, activeIndex, handleClick) {
  if (!data) return null;

  return data.map((d, index) => {
    return (
      <div key={`accordion${d.topic ? d.topic + index : index}`}
          className="mobileFlex__div">
        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={handleClick}>
            <Icon name="dropdown" />
            {d.topic}
          </Accordion.Title>
          <Accordion.Content 
          active={activeIndex === index}>
            <>
              {d.text ? <p>{d.text}</p> : null}
              {d.html ? WithHTML(d.html) : null}
              {d.url ? (
                <>
                  {LinkWithoutPopupBold(d.topic, d.url, d.url_text)}
                  {d.source ? WithSource(d.url, d.source, d.source_url) : null}
                </>
              ) : null}
              {d.links ? displayLinks(d.links, d.topic) : null}
              {d.logo ? WithLogo(d.source_url, d.logo, null, 'small') : null}
              {d.image ? (
                <>
                  {WithImage(d.url, null, d.image, 'massive')}
                  {d.source ? WithSource(d.url, d.source, d.source_url) : null}
                </>
              ) : null}
              {d.pdf ? WithPDF(d.pdf, d.pdf_title) : null}
              {d.subfields ? <>{displaySubfields(d.subfields)} </> : <></>}
            </>
          </Accordion.Content>
        </Accordion>
      </div>
    );
  });
}
