import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';


export default ({ channelName }) => (
  <div className="topbar">

    <div className="room-info" style={{ display: 'flex' }}>
      <p className="room-name">{channelName}</p>
      <div
        className="logout"
        style={{ alignSelf: 'center', marginRight: '20px' }}>
        <Link key="logout" to="/logout">
          <Trans
            i18nKey="chat.sideBar.logOut"
          />
        </Link>
      </div>
      {/* <p className="room-users">users</p> */}
    </div>
  </div>
);
