import i18n from 'i18next';
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';

import translationFI from './i18n/locales/omadiabetes.fi/fi.json'
import translationEN from './i18n/locales/mydiabetes.chat/en.json'
import { currentWebsiteURL } from './readCurrentWebHostname.js';

const resources = {
  en: {
    translation: translationEN
  },
  fi: {
    translation: translationFI
  }
}

// TODO this won't work when we need multiple languages per website (swedish on omadiabetes.fi)

const languageBasedOfHost = (currentWebsiteURL === "omadiabetes.fi") ? "fi" : "en";

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: languageBasedOfHost,
    // backend: {
    //   loadPath: '/locales/{{lng}}.json'
    // },
    detection: {
      // order and from where user language should be detected
      order: ['cookie', 'navigator'], //default ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupCookie: 'language',
      caches: []
    },
    // fallbackLng: 'fi',
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults

    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false
    }
  })
  .then(() => {
    moment.locale(i18next.language);
  });

export default i18n;
