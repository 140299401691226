import React, { Component } from 'react';

import { displaySegment } from './components/DisplaySegment';
import { displayAccordion } from './components/DisplayAccordion';
import { Image } from 'semantic-ui-react';
import importantContact from './../Assets/Banner/ImportantContact.jpeg'; // Tell Webpack this JS file uses this image

import mydiabetes_chat_data from "./data/mydiabetes.chat/ImportantContacts";
import omadiabetes_fi_data from './data/omadiabetes.fi/ImportantContacts';
import { currentWebsiteURL } from '../readCurrentWebHostname';

// TODO create more modular way!
let jsonDATA;
if (currentWebsiteURL === "omadiabetes.fi") {
  jsonDATA = omadiabetes_fi_data;
}

if (currentWebsiteURL === "mydiabetes.chat") {
  jsonDATA = mydiabetes_chat_data;
}

class ImportantContacts extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  render () {
    const { activeIndex } = this.state;
    return (
      <>
        <div className="showOnlyOnDesktop">
          <div className="centerOnPage">
            <div className="desktopFlex">
              {displaySegment(jsonDATA, activeIndex, this.handleClick)}
            </div>
          </div>
        </div>
        <div className="showOnlyOnMobile">
          <div className="centerOnPage">
            <Image src={importantContact} className="banners" />
            {displayAccordion(jsonDATA, activeIndex, this.handleClick)}
          </div>
        </div>
      </>
    );
  }
}

export default ImportantContacts;
