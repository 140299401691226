import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import { displayAccordion } from './components/DisplayAccordion';
import { displaySegment } from './components/DisplaySegment';
import diabetesCounceling from './../Assets/Banner/DiabetesCounceling.jpg'; // Tell Webpack this JS file uses this image

import mydiabetes_chat_data from "./data/mydiabetes.chat/DiabetesCounceling";
import omadiabetes_fi_data from './data/omadiabetes.fi/DiabetesCounceling';
import { currentWebsiteURL } from '../readCurrentWebHostname';

// TODO create more modular way!
let jsonDATA;
if (currentWebsiteURL === "omadiabetes.fi") {
  jsonDATA = omadiabetes_fi_data;
}

if (currentWebsiteURL === "mydiabetes.chat") {
  jsonDATA = mydiabetes_chat_data;
}

class DiabetesCounceling extends Component {
  state = {
    activeIndex: -1,
    numPages: null,
    pageNumber: 1
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleColorChange = e => this.setState({ color: e.target.value });

  handleChange = (e, data) => this.setState(data);

  render () {
    const { activeIndex } = this.state;

    return (
      <>
        <div className="showOnlyOnDesktop">
          <div className="centerOnPage">
          <div className="desktopFlex">{displaySegment(jsonDATA, activeIndex, this.handleClick)}</div>
          </div>
        </div>
        <div className="showOnlyOnMobile">
          <div className="centerOnPage">
            <Image src={diabetesCounceling} className="banners"/>
            {displayAccordion(jsonDATA, activeIndex, this.handleClick)}
          </div>
        </div>
        <div />
      </>
    );
  }
}
export default DiabetesCounceling;
