import React from 'react';
import { Header, Segment, Image } from 'semantic-ui-react';
import { randomBytes } from 'crypto';
import { HTMLtarget } from '../index';
import PPSHPLogo from './../Assets/logos/ppshp.png';
import emergencyContacts from './../Assets/Banner/EmergencyContacts.png'; // Tell Webpack this JS file uses this image

import mydiabetes_chat_data from "./data/mydiabetes.chat/ImportantContacts";
import omadiabetes_fi_data from './data/omadiabetes.fi/ImportantContacts';
import { currentWebsiteURL } from '../readCurrentWebHostname';

// TODO create more modular way!
let jsonDATA;
if (currentWebsiteURL === "omadiabetes.fi") {
  jsonDATA = omadiabetes_fi_data;
}

if (currentWebsiteURL === "mydiabetes.chat") {
  jsonDATA = mydiabetes_chat_data;
}

const displayContactInformation = jsonDATA.map((d, index) => {
  if (index <= 1) {
    return (
      <div
        className="blockContainer__div"
        key={d.url ? d.url + index : randomBytes}>
        <div className="contactInformation" style={{ border: 0 }}>
          <b>
            <a href={d.url ? d.url : null} target={HTMLtarget}>
              {d.topic ? d.topic : null}
            </a>
          </b>
          {d.info
            ? d.info.map(i => (
                <div
                  key={`info-${i}`}
                  dangerouslySetInnerHTML={{ __html: i }}
                />
              ))
            : null}
        </div>
      </div>
    );
  } else {
    return null;
  }
});

function Frontpage () {
  return (
    <>
      <div className="showOnlyOnDesktop">
        <div className="centerOnPage">
          <div className="insideContainer">
            <Segment>
              <div className="contactInformationDetail">
                <Header
                  as="h2"
                  textAlign="center"
                  style={{
                    color: 'black',
                    border: '2px solid #D45605',
                    borderRadius: '15px',
                    backgroundColor: '#fff',
                    width: '40%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem'
                  }}>
                  {(currentWebsiteURL === "omadiabetes.fi") ? "Tärkeät yhteystiedot" : "Important Contact information"}
                  {/* // TODO translate!!  */}
                  {(currentWebsiteURL === "omadiabetes.fi") ? <Image src={PPSHPLogo} spaced="left" /> : "" }
                </Header>
                <div className="blockContainer">
                  {displayContactInformation}
                </div>
              </div>
            </Segment>
          </div>
        </div>
      </div>

      <div className="showOnlyOnMobile">
        <div className="centerOnPage">
          <div className="emergencyContacts" />
          <div className="insideContainer__EC">
            <Segment>
              <Image
                src={emergencyContacts}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '40%',
                  marginTop: '1rem'
                }}
              />
              <div className="blockContainer__EC">
                {displayContactInformation}
              </div>
            </Segment>
          </div>
        </div>
      </div>
    </>
  );
}

export default Frontpage;
