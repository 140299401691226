import React, {Component} from 'react';
import background from './../../Assets/backgrounds/background.jpg'; // Tell Webpack this JS file uses this image
import { Image } from 'semantic-ui-react';
import './Header.scss';


class Header extends Component {
    render () {
        return (
            <Image src={background} className="image1 "/>
        )
    }
}

export default Header
    