import React from 'react';
import ReactGA from 'react-ga';
import { HTMLtarget } from '../../index';

export function WithSource (url, source, source_url) {
  return (
    <div className="linkContainer__source">
      <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
        Lähde:
        <ReactGA.OutboundLink eventLabel={source} to={source_url ? source_url : url} href={source_url ? source_url : url} target={HTMLtarget}>
          {source}
        </ReactGA.OutboundLink>
      </div>
    </div>
  );
}
