import React from 'react';

import './SideDrawer.scss';
import Links from '../Links';


export const SideDrawer = props => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  return (
    <nav className={drawerClasses}>
      <Links
        closeDrawer={props.closeHandler}
        changeTextHandler={props.changeTextHandler}
        changeLanguage={props.changeLanguage}
      />
      
    </nav>
  );
};
