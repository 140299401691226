import React, {Component} from 'react';
import companyLogo from './../../Assets/logos/companyLogo.png'
import { Image } from 'semantic-ui-react';
import './footer.scss';
import { Translation } from 'react-i18next';

class Footer extends Component {
    render () {
        return (
            <div className="container">
                <Image src={companyLogo} style={{width: '3%', height: '3.5rem'}}/>
                <Translation>
                    {t => (
                            <h4>{t('footer.poweredBy')} <a href="https://www.prowellness.com">{t('footer.companyName')}</a></h4>
                    )}
                </Translation>
            </div>
        )
    }
}

export default Footer
