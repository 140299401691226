import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import { displayAccordion } from './components/DisplayAccordion';
import { displaySegment } from './components/DisplaySegment';
import specialSituation from './../Assets/Banner/SpecialSituations.png'; // Tell Webpack this JS file uses this image

import mydiabetes_chat_data from "./data/mydiabetes.chat/SpecialSituations";
import omadiabetes_fi_data from './data/omadiabetes.fi/SpecialSituations';
import { currentWebsiteURL } from '../readCurrentWebHostname';

// TODO create more modular way!
let jsonDATA;
if (currentWebsiteURL === "omadiabetes.fi") {
  jsonDATA = omadiabetes_fi_data;
}

if (currentWebsiteURL === "mydiabetes.chat") {
  jsonDATA = mydiabetes_chat_data;
}

export default class SpecialSituations extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render () {
    const { activeIndex } = this.state;

    return (
      <>
        <div className="showOnlyOnDesktop">
          <div className="centerOnPage">
          <div className="desktopFlex">{displaySegment(jsonDATA, activeIndex, this.handleClick)}</div>
          </div>
        </div>
        <div className="showOnlyOnMobile">
          <div className="centerOnPage">
            <Image src={specialSituation} className="banners"/>
            {displayAccordion(jsonDATA, activeIndex, this.handleClick)}
          </div>
        </div>
      </>
    );
  }
}
