import React from 'react';
import { NavLink } from 'react-router-dom';
import { Translation } from 'react-i18next';
// import finFlag from '../Assets/icons/flag-finland@2x.png';
// import engFlag from '../Assets/icons/flag-united-kingdom@2x.png';
import { Grid } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faBreadSlice,
  faAddressBook,
  faRunning,
  faHandHoldingHeart,
  faNewspaper,
  faHandshake,
  faHospital
} from '@fortawesome/free-solid-svg-icons';
import { isChatEnabled } from '../isChatEnabled';

//{props.contentProvider}
// read oys/tays/etc..

const NavBarLink = props => {
  return (
    <NavLink
      {...props}
      activeClassName="active"
      className="linkButton"
      style={{ textAlign: 'center' }}
    />
  );
};

const Links = props => {
  return (
    <>
      <div className="showOnlyOnDesktop">
        <Translation>
          {t => (
            <div className="nav">
              <ul>
                {isChatEnabled && <li>
                  <NavBarLink
                    to="/ViewChat"
                    onClick={() => {
                      props.closeDrawer();
                      props.changeTextHandler(t('page.links.messages'));
                    }}>
                    {t('page.links.messages')}
                  </NavBarLink>
                  </li> }
                <li>
                  <NavBarLink
                    to="/Carbohydrates"
                    onClick={() => {
                      props.closeDrawer();
                      props.changeTextHandler(t('page.links.carbohydrates'));
                    }}>
                    {t('page.links.carbohydrates')}
                  </NavBarLink>
                </li>
                <li>
                  <NavBarLink
                    to="/ImportantContacts"
                    onClick={() => {
                      props.closeDrawer();
                      props.changeTextHandler(
                        t('page.links.importantContacts')
                      );
                    }}>
                    {t('page.links.importantContacts')}
                  </NavBarLink>
                </li>
                <li>
                  <NavBarLink
                    to="/SpecialSituations"
                    onClick={() => {
                      props.closeDrawer();
                      props.changeTextHandler(
                        t('page.links.specialSituations')
                      );
                    }}>
                    {t('page.links.specialSituations')}
                  </NavBarLink>
                </li>
                <li>
                  <NavBarLink
                    to="/DiabetesCounceling"
                    onClick={() => {
                      props.closeDrawer();
                      props.changeTextHandler(
                        t('page.links.diabetesCouncelingShort')
                      );
                    }}>
                    {t('page.links.diabetesCounceling')}
                  </NavBarLink>
                </li>
                <li>
                  <NavBarLink
                    to="/NewsAndInfo"
                    onClick={() => {
                      props.closeDrawer();
                      props.changeTextHandler(t('page.links.newsAndInfo'));
                    }}>
                    {t('page.links.newsAndInfo')}
                  </NavBarLink>
                </li>
                <li>
                  <NavBarLink
                    to="/Benefits"
                    onClick={() => {
                      props.closeDrawer();
                      props.changeTextHandler(t('page.links.benefits'));
                    }}>
                    {t('page.links.benefits')}
                  </NavBarLink>
                </li>
              </ul>
            </div>
          )}
        </Translation>
      </div>

      <div className="showOnlyOnMobile">
        <Translation>
          {t => (
            <div className="nav__mobile">
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    {isChatEnabled &&
                    <NavBarLink
                      to="/ViewChat"
                      onClick={() => {
                        props.closeDrawer();
                        props.changeTextHandler(
                          <FontAwesomeIcon icon={faComments} />
                        );
                      }}>
                      <h1>
                        <FontAwesomeIcon icon={faComments} />
                      </h1>
                      {t('page.links.messages')}
                    </NavBarLink>}
                  </Grid.Column>
                  <Grid.Column>
                    <NavBarLink
                      to="/FrontPage"
                      onClick={() => {
                        props.closeDrawer();
                        props.changeTextHandler(
                          <FontAwesomeIcon icon={faHospital} />
                        );
                      }}>
                      <h1>
                        <FontAwesomeIcon icon={faHospital} />
                      </h1>

                      {t('page.links.emergencyContacts')}
                    </NavBarLink>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <NavBarLink
                      to="/ImportantContacts"
                      onClick={() => {
                        props.closeDrawer();
                        props.changeTextHandler(
                          <FontAwesomeIcon icon={faAddressBook} />
                        );
                      }}>
                      <h1>
                        <FontAwesomeIcon icon={faAddressBook} />
                      </h1>
                      {t('page.links.importantContacts')}
                    </NavBarLink>
                  </Grid.Column>
                  <Grid.Column>
                    <NavBarLink
                      to="/Carbohydrates"
                      onClick={() => {
                        props.closeDrawer();
                        props.changeTextHandler(
                          <FontAwesomeIcon icon={faBreadSlice} />
                        );
                      }}>
                      <h1>
                        <FontAwesomeIcon icon={faBreadSlice} />
                      </h1>
                      {t('page.links.carbohydrates')}
                    </NavBarLink>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <NavBarLink
                      to="/DiabetesCounceling"
                      onClick={() => {
                        props.closeDrawer();
                        props.changeTextHandler(
                          <FontAwesomeIcon icon={faHandHoldingHeart} />
                        );
                      }}>
                      <h1>
                        <FontAwesomeIcon icon={faHandHoldingHeart} />
                      </h1>
                      {t('page.links.diabetesCounceling')}
                    </NavBarLink>
                  </Grid.Column>
                  <Grid.Column>
                    <NavBarLink
                      to="/SpecialSituations"
                      onClick={() => {
                        props.closeDrawer();
                        props.changeTextHandler(
                          <FontAwesomeIcon icon={faRunning} />
                        );
                      }}>
                      <h1>
                        <FontAwesomeIcon icon={faRunning} />
                      </h1>
                      {t('page.links.specialSituations')}
                    </NavBarLink>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <NavBarLink
                      to="/Benefits"
                      onClick={() => {
                        props.closeDrawer();
                        props.changeTextHandler(
                          <FontAwesomeIcon icon={faHandshake} />
                        );
                      }}>
                      <h1>
                        <FontAwesomeIcon icon={faHandshake} />
                      </h1>
                      {t('page.links.benefits')}
                    </NavBarLink>
                  </Grid.Column>
                  <Grid.Column>
                    <NavBarLink
                      to="/NewsAndInfo"
                      onClick={() => {
                        props.closeDrawer();
                        props.changeTextHandler(
                          <FontAwesomeIcon icon={faNewspaper} />
                        );
                      }}>
                      <h1>
                        <FontAwesomeIcon icon={faNewspaper} />
                      </h1>
                      {t('page.links.newsAndInfo')}
                    </NavBarLink>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </Translation>
      </div>
    </>
  );
};

export default Links;
