import React from 'react';
import { Image } from 'semantic-ui-react';
import { logos, images } from '../../index';

export function WithImage (url, logo, image, size = 'small') {
  let imageSource;
  if (logo) {
    imageSource = logos[`${logo}`];
  }
  if (image) {
    imageSource = images[`${image}`];
  }

  return <Image href={url} src={imageSource} size={size} />;
}
