import { currentWebsiteURL } from "./readCurrentWebHostname";

let chatEnabled = false;

if (currentWebsiteURL === "omadiabetes.fi") {
  chatEnabled = true;
}

if (currentWebsiteURL === 'mydiabetes.chat') {
  chatEnabled = true;
}

export const isChatEnabled = chatEnabled;