import { ApolloClient } from 'apollo-client';
// import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_SERVER_URL_WEB}/graphql`,
  credentials: 'include',
  fetch
});

// const httpLink = createHttpLink({
//   uri: `${process.env.REACT_APP_SERVER_URL_WEB}/graphql`,
//   // credentials: 'include',
//   fetch
// });

const middlewareLink = setContext(() => ({
  headers: {
    'x-token': localStorage.getItem('token'),
    'x-refresh-token': localStorage.getItem('refreshToken')
  }
}));

const afterWareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const {
      response: { headers }
    } = operation.getContext();

    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');
      // console.log(token);
      // console.log(refreshToken);

      if (token) {
        localStorage.setItem('token', token);
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
    }
    return response;
  })
);

const httplinkWithMiddleware = afterWareLink.concat(
  // middlewareLink.concat(httpLink)
  middlewareLink.concat(uploadLink)
);

// Subscriptions
export const wsLink = new WebSocketLink({
  // uri: 'ws://localhost:4000',
  uri: `${process.env.REACT_APP_SERVER_URL_WS}/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: {
      token: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refreshToken')
    }
    /*
    connectionParams: {
      token:
        console.log(
          'connectionParams token: ',
          localStorage.getItem('token')
        ) || localStorage.getItem('token'),

      refreshToken:
        console.log(
          'connectionParams refreshToken: ',
          localStorage.getItem('refreshToken')
        ) || localStorage.getItem('refreshToken')
    }*/
  }
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httplinkWithMiddleware
);

export default new ApolloClient({
  link,
  cache: new InMemoryCache()
});
