import React from 'react';
import { Trans } from 'react-i18next';
import { Sticky } from 'react-uikit3';
import Links from '../Links';
import { DrawerToggleButton } from '../SideDrawer/DrawerToggleButton';
import './Toolbar.scss';
const { detect } = require('detect-browser')

const browser = detect();

export const Toolbar = props => {
  if (browser.name === 'ie') {
    return (
      <>
        <div className="showOnlyOnDesktop">
          <div className="centerOnPage">
            <div className="desktopFlex">
                <header className="toolbar">
                  <nav className="toolbar__navigation">
                    <div className="toolbar__navigation__middle">
                      <div className="toolbar_navigation-items">
                        <Links
                          changeTextHandler={props.changeTextHandler}
                          closeDrawer={props.closeHandler}
                          contentProvider={props.contentProvider}
                          changeLanguage={props.changeLanguage}
                          style={{ color: '#fff' }}
                        />
                      </div>
                    </div>
                    <div className="toolbar__pagename">{props.displayText}</div>
                  </nav>
                </header>
            </div>
          </div>
        </div>

        <div className="showOnlyOnMobile">
          <header className="toolbar__mobile">
            <nav className="toolbar__navigation" style={{ margin: '4px' }}>
              <div className="toolbar__navigation__left">
                <div className="toolbar__toggle-button">
                  <DrawerToggleButton click={props.drawerClickHandler} />
                </div>
                <div
                  style={{
                    color: '#fff',
                    marginLeft: '0.5rem',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  }}>
                  <Trans i18nKey="title" />
                </div>
              </div>

              <div className="toolbar__navigation__middle">
                <div className="toolbar_navigation-items">
                  <Links
                    changeTextHandler={props.changeTextHandler}
                    closeDrawer={props.closeHandler}
                    contentProvider={props.contentProvider}
                    changeLanguage={props.changeLanguage}
                  />
                </div>
              </div>
              <div className="toolbar__pagename">{props.displayText}</div>
            </nav>
          </header>
        </div>
      </>
    );
  } else {


  return (
    <>
      <div className="showOnlyOnDesktop">
        <div className="centerOnPage">
          <div className="desktopFlex">
            <Sticky>
              <header className="toolbar">
                <nav className="toolbar__navigation">
                  <div className="toolbar__navigation__middle">
                    <div className="toolbar_navigation-items">
                      <Links
                        changeTextHandler={props.changeTextHandler}
                        closeDrawer={props.closeHandler}
                        contentProvider={props.contentProvider}
                        changeLanguage={props.changeLanguage}
                        style={{ color: '#fff' }}
                      />
                    </div>
                  </div>
                  <div className="toolbar__pagename">{props.displayText}</div>
                </nav>
              </header>
            </Sticky>
          </div>
        </div>
      </div>

      <div className="showOnlyOnMobile">
        <header className="toolbar__mobile">
          <nav className="toolbar__navigation" style={{ margin: '4px' }}>
            <div className="toolbar__navigation__left">
              <div className="toolbar__toggle-button">
                <DrawerToggleButton click={props.drawerClickHandler} />
              </div>
              <div
                style={{
                  color: '#fff',
                  marginLeft: '0.5rem',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>
                <Trans i18nKey="title" />
              </div>
            </div>

            <div className="toolbar__navigation__middle">
              <div className="toolbar_navigation-items">
                <Links
                  changeTextHandler={props.changeTextHandler}
                  closeDrawer={props.closeHandler}
                  contentProvider={props.contentProvider}
                  changeLanguage={props.changeLanguage}
                />
              </div>
            </div>
            <div className="toolbar__pagename">{props.displayText}</div>
          </nav>
        </header>
      </div>
    </>
  );
  }
};
