import React from 'react';
import ReactGA from 'react-ga';

import { pdfs, HTMLtarget } from '../../index';

export function WithPDF (pdf, title) {
  let pdfSource;
  if (pdf) {
    pdfSource = pdfs[`${pdf}`];
  }

  return (
    <b>
      <ReactGA.OutboundLink eventLabel={pdfSource} to={pdfSource} href={pdfSource} target={HTMLtarget}>
        {title}
      </ReactGA.OutboundLink>
    </b>
  );
}
