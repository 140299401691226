// [{path: 'login', message: 'does not exists'}]
/*
{
  login: ['e1', 'e2'..]
}
*/

export default errors =>
  errors.reduce((acc, cv) => {
    if (cv.path in acc) {
      acc[cv.path].push(cv.message);
    } else {
      acc[cv.path] = [cv.message];
    }

    return acc;
  }, {});
