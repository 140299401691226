import React, { Component } from 'react';

class DiabetesFacts extends Component {
  state = {};
  render () {
    return (
      <div className="container-fluid">
        <div className="insideContainer">
          <h1>DiabetesFacts</h1>
          <p />
        </div>
      </div>
    );
  }
}

export default DiabetesFacts;
