import React from 'react';
import { LinkWithoutPopupBold } from './LinkWithoutPopup';
import { WithSource } from './WithSource';

export function displayLinks (data, outerTopic) {
  if (!data) return null;

  return data.map((d, index) => (
    <div className="linkContainer" key={`info-${d.url + index}`}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {outerTopic === d.topic ? (
          LinkWithoutPopupBold(d.text, d.url)
        ) : (
          <div>
            <b>{LinkWithoutPopupBold(d.topic, d.url)}</b>
            <div>{d.text}</div>
            {d.source ? WithSource(d.url, d.source, d.source_url) : null}
          </div>
        )}
      </div>
    </div>
  ));
}
