import React, { Component } from 'react';

class OwnGoals extends Component {
  state = {};
  render () {
    return (
      <div className="insideContainer">
        <h1>Own goals</h1>
        <p />
      </div>
    );
  }
}

export default OwnGoals;
