import React from 'react';
import { Icon, Modal, Image } from 'semantic-ui-react';
import { images } from '../..';

export function WithModal (textORtopic, url_image) {
  return (
    <Modal
      closeIcon
      trigger={
        <p
          style={{
            color: 'var(--link-color)',
            textDecoration: 'none',
            cursor: 'pointer'
          }}>
          <Icon name="image" />
          {textORtopic}
        </p>
      }>
      <Image src={images[`${url_image}`]} />
    </Modal>
  );
}
