import React from 'react';
import { Route, Redirect, HashRouter } from 'react-router-dom';

import withTracker from '../withTracker'

import decode from 'jwt-decode';
import smoothscroll from 'smoothscroll-polyfill';

// Top of page
import Layout from '../components/Layout';
// Pages
import Frontpage from '../pages/Frontpage';
import Benefits from '../pages/Benefits';
import SpecialSituations from '../pages/SpecialSituations';
import DiabetesCounceling from '../pages/DiabetesCounceling';
import NewsAndInfo from '../pages/NewsAndInfo';
import ImportantContacts from '../pages/ImportantContacts';
import Carbohydrates from '../pages/Carbohydrates';
import Register from './Register';
import Login from './Login';
import Logout from './Logout';
import ChangePassword from './ChangePassword';
import CreateTeam from './CreateTeam';
import ViewChat from './ViewChat';
import OwnGoals from '../pages/OwnGoals';
import DiabetesFacts from '../pages/DiabetesFacts';
import WidthLayouts from '../routes/WidthLayouts';
import ViewChatMobile from '../routes/ViewChatMobile';
import ViewChatWebsite from '../routes/ViewChatWebsite';
import { isChatEnabled } from '../isChatEnabled';

// kick off the polyfill!
smoothscroll.polyfill();

// Configure ApolloClient for GraphQL

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    decode(token);
    const { exp } = decode(refreshToken);
    if (Date.now() / 1000 > exp) {
      return false;
    }
  } catch (err) {
    return false;
  }

  return true;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login'
          }}
        />
      )
    }
  />
);

export default () => (
  <>
    <HashRouter>
      <>
        <Layout>
          {isChatEnabled && <Route exact path="/register/:token" component={Register} /> }
          {isChatEnabled && <Route exact path="/ChangePassword" component={withTracker(ChangePassword)} /> }
          {isChatEnabled && <Route exact path="/Login" component={withTracker(Login)} /> }
          {isChatEnabled && <Route exact path="/Logout" component={withTracker(Logout)} /> }
          {isChatEnabled && <PrivateRoute path="/CreateTeam" exact component={withTracker(CreateTeam)} /> }
          {isChatEnabled && <Route exact path="/ViewChat" component={withTracker(ViewChat)} /> }
          {isChatEnabled && <PrivateRoute
            path="/ViewChatMobile/:teamId?/:channelId?"
            exact
            component={ViewChatMobile}
          /> }
          {isChatEnabled && <PrivateRoute
            path="/ViewChatWebsite/:teamId?/:channelId?"
            exact
            component={ViewChatWebsite}
          />}
          <Route exact path="/" component={withTracker(WidthLayouts)} />
          <Route exact path="/FrontPage" component={withTracker(Frontpage)} />
          <Route exact path="/Benefits" component={withTracker(Benefits)} />
          <Route exact path="/Carbohydrates" component={withTracker(Carbohydrates)} />
          <Route
            exact
            path="/ImportantContacts"
            component={withTracker(ImportantContacts)}
          />
          <Route
            exact
            path="/DiabetesCounceling"
            component={withTracker(DiabetesCounceling)}
          />
          <Route exact path="/NewsAndInfo" component={withTracker(NewsAndInfo)} />
          <Route exact path="/OwnGoals" component={withTracker(OwnGoals)} />
          <Route
            exact
            path="/SpecialSituations"
            component={withTracker(SpecialSituations)}
          />
          <Route exact path="/DiabetesFacts" component={withTracker(DiabetesFacts)} />
        </Layout>
      </>
    </HashRouter>
  </>
);
