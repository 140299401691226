import React from 'react';
import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import findIndex from 'lodash/findIndex';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';

// import cookie from 'js-cookie';

import Header from '../components/Chat/components/Header';
import SendMessage from '../components/Chat/components/SendMessage';
import Sidebar from '../components/Chat/containers/Sidebar';
import MessageContainer from '../components/Chat/containers/MessageContainer';
import { meQuery } from '../graphql/team';
import { THIS_IS_PATIENT_CHANNEL } from '..';

const ViewChat = ({
  mutate,
  data: { loading, me, error },
  match: {
    params: { teamId, channelId }
  }
}) => {
  if (loading || !me) {
    if (error) {
      return <Redirect to="/login" />;
    }
    return null;
  }

  const { id: currentUserId, displayname, teams } = me;


  if (!teams.length) {
    return <Redirect to="/CreateTeam" />;
  }

  const teamIdInteger = teamId;
  const teamIdx = teamIdInteger ? findIndex(teams, ['id', teamIdInteger]) : 0;
  const team = teamIdx === -1 ? teams[0] : teams[teamIdx];

  const channelIdInteger = channelId;
  const channelIdx = channelIdInteger
    ? findIndex(team.channels, ['id', channelIdInteger])
    : 0;
  let channel =
    channelIdx === -1 ? team.channels[0] : team.channels[channelIdx];

  // const chatOtherUserID = cookie.get('ChatOtherUserID');

  let allOk = true;
  let singleChannel = '';
  // if (chatOtherUserID) {
  //   const singleChannelIdx = chatOtherUserID
  //     ? findIndex(team.channels, ['id', chatOtherUserID])
  //     : 0;
  //   singleChannel =
  //     singleChannelIdx === -1
  //       ? team.channels[0]
  //       : team.channels[singleChannelIdx];

  //   channel = singleChannel;

  //   if (singleChannelIdx === -1) {
  //     // If we should show only one patient (chat with patientContext)
  //     // and we don't find that patient guid in our channels we have a problem
  //     // so we don't give anything on screen
  //     // insert text to show what's wrong
  //     allOk = false;
  //     singleChannel = null;
  //   }
  // }

  if (!allOk) {
    return <div>Chat unavailable</div>;
  }

  let displayChannelName = channel ? channel.name : '';
  if (channel) {
    if (channel.name === THIS_IS_PATIENT_CHANNEL) {
      displayChannelName = team.name;
    }
  }


  return (
    <>
    <div className="showOnlyOnDesktop">
      <div className="chat-app">
        <Sidebar
          teams={teams.map(t => ({
            id: t.id,
            letter: t.name.charAt(0).toUpperCase()
          }))}
          team={team}
          displayName={displayname}
          currentUserId={currentUserId}
        />

        <div className="chat-content">
          {channel && <Header channelName={displayChannelName} />}
          {channel && (
            <MessageContainer
              channelId={channel.id}
              currentUserId={currentUserId}
              displayName={displayname}
            />
          )}
          {channel && (
            <SendMessage
              channelId={channel.id}
              placeholder={singleChannel ? `to ${channel.name}` : ''}
              type="button"
              onSubmit={async text => {
                await mutate({ variables: { text, channelId: channel.id } });
              }}
            />
          )}
        </div>
      </div>
    </div>
    </>
  );
};

const createMessageMutation = gql`
  mutation($channelId: ID!, $text: String) {
    createMessage(channelId: $channelId, text: $text)
  }
`;

export default compose(
  graphql(meQuery, { options: { fetchPolicy: 'network-only' } }),
  graphql(createMessageMutation)
)(ViewChat);
