import React from 'react';
import { Image, Modal, Icon, Accordion } from 'semantic-ui-react';
import { images } from '../..';
import { displayLinks } from './DisplayLinks';
import { displaySubfields } from './DisplaySubfields';
import { LinkWithoutPopupBold } from './LinkWithoutPopup';
import { WithHTML } from './WithHTML';
import { WithLogo } from './WithLogo';
import { WithPDF } from './WithPDF';
import { WithSource } from './WithSource';

export function displaySegment (data, activeIndex, handleClick) {
  if (!data) return null;

  return data.map((d, index) => {
    return (
      <div key={`accordion${d.topic ? d.topic + index : index}`}>
        <div className="desktopFlex__div">
          <Accordion>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={handleClick}
              className="title">
              {d.topic}
              <Icon name="dropdown" style={{ float: 'right' }} />
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === index}
              className="details">
              <>
                {/* <b>
                <a href={d.url} target={HTMLtarget}>{d.topic}</a>
              </b> */}
                <div className="details__content">
                  {d.text ? <p>{d.text}</p> : null}
                  {d.html ? WithHTML(d.html) : null}
                  {d.url ? (
                    <>
                      {LinkWithoutPopupBold(d.topic, d.url, d.url_text)}
                      {d.source
                        ? WithSource(d.url, d.source, d.source_url)
                        : null}
                    </>
                  ) : null}
                  {d.info ? (
                    <>
                      <div className="contactInformation">
                        {d.info.map(i => (
                          <div
                            key={`info-${i}`}
                            dangerouslySetInnerHTML={{ __html: i }}
                          />
                        ))}
                      </div>
                    </>
                  ) : null}
                  {d.links ? displayLinks(d.links, d.topic) : null}
                  {d.logo ? WithLogo(d.source_url, d.logo) : null}
                  {d.image ? (
                    <>
                      <Modal
                        closeIcon
                        trigger={
                          <Image
                            src={images[`${d.image}`]}
                            size="massive"
                            className="withPointer"
                          />
                        }>
                        <Image src={images[`${d.image}`]} />
                      </Modal>
                      {d.source
                        ? WithSource(d.url, d.source, d.source_url)
                        : null}
                    </>
                  ) : null}
                  {d.pdf ? WithPDF(d.pdf, d.pdf_title) : null}
                  {d.subfields ? displaySubfields(d.subfields) : null}
                </div>
              </>
            </Accordion.Content>
          </Accordion>
        </div>
      </div>
    );
  });
}
